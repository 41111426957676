<template>
  <div>
    <PageHeader :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Company Name"
                rules="required"
              >
                <TextInput
                  v-model="form.companyName"
                  type="text"
                  :label="$t('Company Name')"
                  :tooltipMessage="$t('Company Name')"
                  :required="true"
                  :placeholder="$t('Enter Company Name')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Address Line 1"
                rules="required"
              >
                <TextInput
                  v-model="form.addressLine1"
                  type="text"
                  :label="$t('Address Line 1')"
                  :required="true"
                  :placeholder="$t('Enter Address Line 1')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.addressLine2"
                type="text"
                :label="$t('Address Line 2')"
                :placeholder="$t('Enter Address Line 2')"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="City"
                rules="required"
              >
                <TextInput
                  v-model="form.city"
                  type="text"
                  :label="$t('City')"
                  :required="true"
                  :placeholder="$t('Enter City')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="ZIP"
                rules="required"
              >
                <TextInput
                  v-model="form.zipCode"
                  type="text"
                  :label="$t('ZIP')"
                  :required="true"
                  :placeholder="$t('Enter ZIP')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <validation-provider
                #default="{ errors }"
                name="Country"
                rules="required"
              >
                <TextInput
                  v-model="form.country"
                  type="text"
                  :label="$t('Country')"
                  :placeholder="$t('Enter Country')"
                  :required="true"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.vatId"
                type="text"
                :label="$t('VAT ID')"
                :placeholder="'+123XXXXXX'"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <TextInput
                v-model="form.phone"
                type="text"
                :label="$t('Phone')"
                placeholder="+49XXXXXXXXXXX"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="form-group">
                <label class="input-label form-label" for="cars">{{
                  $t("Status")
                }}</label>
                <select class="input-form form-control" v-model="form.status">
                  <option value="active">
                    {{ $t("Active") }}
                  </option>
                  <option value="inactive">
                    {{ $t("Inactive") }}
                  </option>
                </select>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1"></b-col>
            <b-col cols="12" lg="4" md="4" class="mb-1">
              <div class="form-group">
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{
                    $t("Assigned to Sales Partner")
                  }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Sales Partner"
                  rules="required"
                >
                  <MultiSelectInput
                    v-model="form.salesPartnerId"
                    :key="form.salesPartnerId"
                    :options="salesPartners"
                    label="companyName"
                    trackBy="id"
                    moduleName="customers"
                    :multiple="false"
                    :action="'salesPartners'"
                    :query="{ isSalesPartner: true }"
                    ><template #option="{ props }">
                      <div>
                        <p>
                          {{ props.option.companyNumber }} -
                          {{ props.option.companyName }}
                        </p>
                      </div>
                    </template>
                  </MultiSelectInput>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" lg="4" md="4" class="mb-1">
              <div class="form-group">
                <label class="input-label form-label"
                  ><span style="color: red">*</span>&nbsp;{{
                    $t("Assigned to Reseller")
                  }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Reseller"
                  rules="required"
                >
                  <MultiSelectInput
                    v-model="form.resellerId"
                    :key="form.resellerId"
                    :options="resellers"
                    label="companyName"
                    trackBy="id"
                    moduleName="customers"
                    :multiple="false"
                    :action="'resellers'"
                    :query="{ isReseller: true }"
                    ><template #option="{ props }">
                      <div>
                        <p>
                          {{ props.option.companyNumber }} -
                          {{ props.option.companyName }}
                        </p>
                      </div>
                    </template>
                  </MultiSelectInput>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" lg="4" md="4" class="mb-1">
              <div class="form-group">
                <label class="input-label form-label"
                  >{{ $t("Assigned to Service Partner") }}
                </label>
                <MultiSelectInput
                  v-model="form.servicePartnerId"
                  :key="form.servicePartnerId"
                  :options="servicePartners"
                  label="companyName"
                  trackBy="id"
                  moduleName="customers"
                  :multiple="false"
                  :action="'servicePartners'"
                  :query="{ isServicePartner: true }"
                  ><template #option="{ props }">
                    <div>
                      <p>
                        {{ props.option.companyNumber }} -
                        {{ props.option.companyName }}
                      </p>
                    </div>
                  </template>
                </MultiSelectInput>
              </div>
            </b-col>

            <b-col cols="12" lg="3" md="3" class="mb-1">
              <div class="form-group mt-1">
                <label class="input-label form-label" for="cars">{{
                  $t("Sales Partner")
                }}</label>

                <input
                  id="salesPartner"
                  class="form-check-input-custom ml-2"
                  v-model="form.salesPartner"
                  type="checkbox"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="3" md="3" class="mb-1">
              <div class="form-group mt-1">
                <label class="input-label form-label" for="cars">{{
                  $t("Reseller")
                }}</label>
                <input
                  id="reseller"
                  class="form-check-input-custom ml-2"
                  v-model="form.reseller"
                  type="checkbox"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="3" md="3" class="mb-1">
              <div class="form-group mt-1">
                <label class="input-label form-label" for="cars">{{
                  $t("Service Partner")
                }}</label>
                <input
                  id="reseller"
                  class="form-check-input-custom ml-2"
                  v-model="form.servicePartner"
                  type="checkbox"
                />
              </div>
            </b-col>
             <b-col cols="12" lg="3" md="3" class="mb-1">
              <div class="form-group mt-1">
                <label class="input-label form-label" for="cars">{{
                  $t("End User")
                }}</label>

                <input
                  id="endUser"
                  class="form-check-input-custom ml-2"
                  v-model="form.endUser"
                  type="checkbox"
                />
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="card">
        <div class="card-header" aria-label="Payment Details Header">
          <h3 class="card-title">
            {{ $t("Payment Details") }}
          </h3>
        </div>

        <div class="card-body" aria-label="Payment Detials Body">
          <div class="row">
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <text-input
                v-model="form.invoiceEmailAddress"
                :label="$t('Invoice Email Address')"
                aria-label="Invoice Email Address Input"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <text-input
                v-model="form.warningMailAddress"
                :label="$t('Warning Mail Address')"
                aria-label="Invoice Email Address Input"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="form-group mt-2">
                <label class="input-label form-label" for="cars">{{
                  $t("Apply Reverse Charge")
                }}</label>

                <input
                  id="active"
                  class="form-check-input-custom ml-2"
                  v-model="form.applyReverseCharge"
                  type="checkbox"
                />
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <text-input
                v-model="form.externalOrderNumber"
                :label="$t('External Order Number')"
                aria-label="External Order Number"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <text-input
                :type="'number'"
                v-model="form.credits"
                :label="$t('DentalTwin Coins')"
                aria-label="DentalTwin Coins"
              />
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <text-input
                v-model="form.notificationMail"
                :label="$t('Notification Mail')"
                aria-label="Notification Mail"
              />
            </b-col>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" aria-label="Bank Details Header">
          <h3 class="card-title">
            {{ $t("Bank Details") }}
          </h3>
        </div>
        <div class="card-body" aria-label="Bank Details Body">
          <div class="w-100">
            <div
              v-for="(bankDetails, index) in form.bankDetails"
              :key="index"
              class="mb-3"
            >
              <b-row>
                <b-col cols="12" lg="4" md="4" class="mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Bank Name"
                    rules="required"
                  >
                    <text-input
                      v-model="bankDetails.bankName"
                      :label="$t('Bank Name')"
                      :required="true"
                      :maxLength="100"
                      aria-label="Bank Name Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="12" lg="4" md="4" class="mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="BIC/SWIFT"
                    rules="required"
                  >
                    <text-input
                      v-model="bankDetails.swift"
                      :label="$t('BIC/SWIFT')"
                      :required="true"
                      :maxLength="100"
                      aria-label="BIC/Swift Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="12" lg="4" md="4" class="d-flex gap-2">
                  <validation-provider
                    #default="{ errors }"
                    name="IBAN"
                    rules="required"
                    class="w-100"
                  >
                    <text-input
                      v-model="bankDetails.iban"
                      :label="$t('IBAN')"
                      :required="true"
                      :maxLength="100"
                      aria-label="IBAN Input"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <div
                    role="button"
                    class="float-center mt-3"
                    @click="deleteBankDetails(index)"
                    aria-label="Delete bank details button"
                  >
                    <feather-icon icon="TrashIcon" size="22" />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <span
              style="cursor: pointer"
              @click="addBankDetails"
              class="font-weight-medium text-primary"
            >
              <feather-icon :icon="'PlusIcon'" size="12" />
              {{ $t("Add bank accounts") }}
            </span>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center justify-content-end gap-2">
        <router-link
          to="/customers"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <b-button
          variant="primary"
          @click="createCustomer"
          class="d-flex align-items-center gap-1"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
          v-if="$can(`${$route.meta.permission}.create`)"
        >
          <feather-icon icon="PlusIcon" size="12" />
          <span>{{ $t("Create Customer") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelect from "vue-multiselect";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapGetters } from "vuex";
import { required, email } from "@validations";

export default {
  components: {
    TextInput,
    PageHeader,
    MultiSelect,
    ValidationProvider,
    MultiSelectInput,
    ValidationObserver,
  },
  computed: {
    ...mapGetters("customers", [
      "resellers",
      "salesPartners",
      "servicePartners",
    ]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Customers"),
          to: "/customers",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
    isFormInvalid() {
      return (
        !this.form.companyName ||
        !this.form.addressLine1 ||
        !this.form.city ||
        !this.form.zipCode ||
        !this.form.country ||
        !this.form.resellerId ||
        !this.form.salesPartnerId ||
        (this.form.bankDetails.length > 0
          ? !this.form.bankDetails.every(
              (bankDetail) =>
                bankDetail.bankName && bankDetail.swift && bankDetail.iban
            )
          : false)
      );
    },
  },
  async created() {
    try {
      this.$store.commit("showLoader", true);
      await this.$store.dispatch("customers/resellers", {
        isReseller: true,
      });
      await this.$store.dispatch("customers/salesPartners", {
        isSalesPartner: true,
      });
      await this.$store.dispatch("customers/servicePartners", {
        isServicePartner: true,
      });
      this.$store.dispatch("customers/getResellerPartner").then((res) => {
        let data = res?.data;
        this.form.salesPartnerId = data?.salesPartnerCompany;
        this.form.resellerId = data?.resellerCompany;
        this.form.servicePartnerId = data?.servicePartnerCompany;
      });
    } catch (e) {
      console.error("api error ___", e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
  data() {
    return {
      form: {
        companyName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        zipCode: "",
        country: "",
        vatId: "",
        phone: "",
        credits: "",
        status: "active",
        bankDetails: [],
        resellerId: "",
        servicePartnerId: "",
        salesPartnerId: "",
        externalOrderNumber: "",
        invoiceEmailAddress: "",
        warningMailAddress: "",
        applyReverseCharge: false,
        reseller: false,
        endUser: false,
        salesPartner: false,
        servicePartner: false,
        notificationMail: "",
      },
    };
  },

  methods: {
    addBankDetails() {
      this.form.bankDetails.push({
        bankName: "",
        swift: "",
        iban: "",
      });
    },
    async deleteBankDetails(index) {
      const confirmed = await this.showConfirmationDialog({
        title: this.$t("Do you want to delete this record?"),
        subText: this.$t("Yes delete it!"),
      });

      if (confirmed) {
        this.form.bankDetails.splice(index, 1);
      }
    },
    async createCustomer() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          await this.$store.dispatch("customers/create", {
            ...this.form,
            salesPartnerId: this.form.salesPartnerId?.id ?? "",
            resellerId: this.form.resellerId?.id ?? "",
            servicePartnerId: this.form.servicePartnerId?.id ?? "",
          });
          this.$router.push("/customers");
        }
      });
    },
  },
};
</script>

<style scoped>
#nprogress {
  position: relative;
  z-index: 9999999;
}

.white-color {
  color: white !important;
}

.vgt-responsive {
  overflow-x: visible !important;
}
</style>
